<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-04-07 13:47:28
 * @LastEditTime : 2022-03-29 15:54:40
 * @LastEditors  : BigBigger
-->
<template>
  <div class="app-page-2 app-content2">
    <van-list
      v-model="listLoading"
      :finished="finished"
      @load="getCourseGroup"
    >
    <div class="top">
      <img src="@/assets/images/h5-logo/logo.png">
        <span  @click="login(0)"  v-if="!getUserInfo">登陆/注册</span>
        <div class="user-info" v-if="getUserInfo" @click="mineClick()">
          <img :src="getUserInfo.headImg">
          <p class="user-tel">{{getUserInfo.name}}</p>
        </div>
    </div>
    <div class="banner">
      <!-- <img src="@/assets/images/banner1.png"> -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) of bannerList" :key="index">
          <img @click="bannerClick(item)" :key="index" :src="item.appImg" :alt="item.carouselName">
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-sticky class="tab-box">
      <div class="type-list">
        <p :class="{'type-active' : activedCourseGroupCategroy.value === item.value}"
        @click="courseGroupCategroyClick(item)" 
        v-for="(item, index) of courseGroupCategroyList" 
        :key="index" :data-value="item.value">{{item.code}}</p>
      </div>
      <div class="type-list-2" v-if="courseGroupSecondCategroyList.length">
        <p :class="{'active' : !secondCategroy}" @click="courseGroupSecondCategroyClick()">全部课程</p>
        <p :class="{'active' : secondCategroy === item.value}"
        @click="courseGroupSecondCategroyClick(item.value)"
        v-for="(item, index) of courseGroupSecondCategroyList"
        :key="index" :data-value="item.value">{{item.code}}</p>
      </div>
    </van-sticky>
    <div class="courseGroupList" v-if="courseGroupList.length > 0">
        <CourseGroupCard :info="item" v-for="(item, index) of courseGroupList" :key="index" @click="courseGroupCardClick" />
    </div>
    <van-empty description="暂无数据" v-if="courseGroupList.length === 0" />
    </van-list>

    <a id="alink" style="display: none;"><span id="spanId">下一步</span></a>
  </div>
</template>

<script>
let courseGroupCategroyAll = { code: '全部课程', value: '' }
import CourseGroupCard from '@/components/courseGroup/CourseGroupCard'
import {
  checkOrder,
  getBanner,
  getCourseGroup,
  getCourseGroupCategroy,
  getCourseGroupSecondCategroy
} from '@/api/api'
import { checkOrderStatus } from '@/views/common/check';
import qs from 'qs'
export default {
  // async asyncData(context) {
  //   let categoryName = context.query.categoryName || ''
  //   // 获取轮播图
  //   let banner = await context.$axios.post(getBanner)
    
  //   // 获取课程组分类
  //   let courseGroupCategroy = await context.$axios.post()
  //   // 分页获取课程组
  //   let data = {
  //     categoryName: categoryName,
  //     page: 1,
  //     limit: 5
  //   }
  //   let courseGroup = await context.$axios.post(getCourseGroup, null,{params: data})
  //   return { 
  //     bannerList: banner.data,
  //     courseGroupCategroyList: [courseGroupCategroyAll, ...courseGroupCategroy.data],
  //     courseGroupList: courseGroup.data,
  //     // activedCourseGroupCategroy: categoryName || courseGroupCategroyAll,
  //     total:courseGroup.total,
  //     page: 2
  //   }

  // },
  data() {
    return{
      activedCourseGroupCategroy: courseGroupCategroyAll, // 选中的课程组分类
      categoryName: '',
      secondCategroy: undefined,
      page: 1,
      limit: 5,
      total: 0,
      courseGroupList: [], // 课程组列表
      loading: false,
      show: true,
      listLoading: false,
      finished: false,
      bannerList: [],
      courseGroupCategroyList: [],
      courseGroupSecondCategroyList: [],
      courseGroupList: [],
      query: {},
      categoryName: ''
    }
  },
  components: {
    CourseGroupCard,
  },
  computed: {
    getUserInfo() {
      return this.$store.state.userInfo || null
    }
  },
  mounted() {
    checkOrderStatus(this),
    this.getBanner()
    let categoryName = this.$route.query.categoryName || sessionStorage.getItem('categoryName') || ''
    this.categoryName = categoryName;
    if(categoryName) {
      this.activedCourseGroupCategroy = { code: categoryName, value: categoryName }
      this.getCourseGroupSecondCategroy();
      if(this.$route.query.categoryTip) {
        this.secondCategroy = this.$route.query.categoryTip
      }
    } else {
      this.activedCourseGroupCategroy = courseGroupCategroyAll
    }
    this.getCourseGroupCategroy()

    //返回刷新
    window.onload = function(){
      var url = window.location.href;
      var ps = url.split("#");
      try{
        if(ps[1] != 1){
          url += "#1";
        }else{
          window.location = ps[0];
        }
      }catch(ex){
        url += "#1";
      }
      window.location.replace(url);

    }

  },
  methods: {
    async getCourseGroupCategroy() {
      let response = await getCourseGroupCategroy()
      if (!response) return this.finished = true; 
      if(+response.returnCode !== 10001) {
        this.finished = true
        return false
      }
      this.courseGroupCategroyList = sessionStorage.getItem('categoryName') ? response.data.filter(i => i.value === this.categoryName) : [courseGroupCategroyAll, ...response.data];
      this.getCourseGroupSecondCategroy()
    },
    async getBanner() {
      let response = await getBanner()
      if(+response.returnCode !== 10001) {
        return false
      }
      this.bannerList =  response.data
    },
    initPage() {
      this.page = 1;
      this.courseGroupList = []
      this.getCourseGroup()
    },
    currentPageChange(currentPage) {
      this.page = currentPage
      this.getCourseGroup()
    },
    login(val = 0) {
      this.$store.dispatch('toggleDialog', true)
    },
    // 课程组卡片点击
    courseGroupCardClick(item) {
      this.$router.push({path: `/course/${item.id}`})
    },
    // 获取课程组
    async getCourseGroup() {
      const params = {
        categoryName: this.categoryName,
        secondCategoryId: this.secondCategroy,
        page: this.page,
        limit: this.limit
      }
      this.listLoading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let response = await getCourseGroup(params)
      this.listLoading = false
      this.$toast.clear()
      if(+response.returnCode !== 10001) {
        this.finished = true
        return false
      }
      this.total = response.total
      if(response.data && response.data.length !== 0) {
        this.courseGroupList = [...this.courseGroupList, ...response.data]
        this.page += 1
      } else {
        this.finished = true
      }
    },
    courseGroupCategroyClick(item) {
      this.$router.push({ path: `/course?categoryName=${item.value}` })
      this.finished = false
      this.secondCategroy = undefined;
      this.courseGroupSecondCategroyList = [];
      this.activedCourseGroupCategroy = item
      this.categoryName = item.value
      this.getCourseGroupSecondCategroy()
      this.initPage()
    },
    courseGroupSecondCategroyClick(value) {
      this.$router.push({ path: `/course?categoryName=${this.categoryName}&categoryTip=${value}` })
      this.finished = false
      this.secondCategroy = value
      this.initPage()
    },
    bannerClick(item){
      if(item.type === 1) {
      window.open(item.val);  
      } else if(item.type === 2) {
      this.$router.push({path:`/course/${item.val}`})
      } else {
      console.warn("暂无跳转")
      }
    },
    getCourseGroupSecondCategroy() {
      if (this.categoryName)
        getCourseGroupSecondCategroy({page: 1,limit: 999, categoryId: this.categoryName}).then(res => {
          this.courseGroupSecondCategroyList = res.data || [];
        })
    },
    mineClick(val) {
      this.$router.push({path: '/mine'})
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!from.name) {
        vm.$nextTick(() => {
          if (vm.$store.state.userInfo?.token)
            checkOrder().then(res => {
              if (+res.returnCode === 10001 && res.data.judgeType)
                vm.$dialog.confirm({
                  message: `系统检测到您的 <span style="color: red;">（${res.data.courseGroupName || '课程订单'}）</span> 已通过审核，请尽快前往支付！`,
                  confirmButtonText: '去查看'
                }).then(() => {
                  vm.$router.push({path: `/mine/mineInfo?type=1`})
                })
            })
        })
      }
    });
  }
}
</script>

<style lang="less" scoped>
.top{
  // width:100%;
  height:0.88rem;
  line-height: 0.88rem;
  padding:0 0.38rem;
  display: flex;
  justify-content:space-between;
  box-shadow: 0px 0px 0.2rem 0px rgba(113, 113, 113, 0.45);
  background:#fff;
}
.top img{
  width:1.95rem;
  height:0.57rem;
  margin:0.15rem 0;
}
.top span{
  font-size: 14px;
  height:0.88rem;
  line-height: 0.88rem;
}
.top .user-info{
  display: flex;
  align-items: center;
}
.top .user-info img{
  width:0.4rem;
  height:0.4rem;
  border-radius:50%;
}
.top .user-info p{
  font-size: 14px;
  padding-left: 0.1rem
}
.banner{
  width:100%;
  height:2.95rem;
  background: #eee;
}
.banner img{
  width:100%;
  height:2.95rem;
}
.type-list{
  display: flex;
  padding:0 3%;
  overflow-x: auto;
  background: #fff;
  border-bottom: 1px solid #f1f1f1;
}
.type-list p{
  font-size: 16px;
  color:#999999;
  line-height: 0.75rem;
  margin-top:0.3rem;
  margin-right: 0.4rem;

  display: block;
  font-size: 16px;
  color:#999;
  flex-grow: 0;
  flex-shrink: 0;
}
.type-list .type-active{
  color:#FB503E;
  font-weight: bold;
  border-bottom:3px #FF3F47 solid;
}
.type-list-2{
  display: flex;
  padding: 0.2rem 3%;
  overflow-x: auto;
  background: #fff;
  font-size: 0.2rem;
  p{
    color: #999999;
    background: #F8F8F8;
    border-radius: 0.4rem;
    padding: 0.1rem 0.2rem;
    margin-right: 2%;
    flex-grow: 0;
    flex-shrink: 0;
    &.active{
      background: #FFEEE5;
      color: #FF5400;
      font-weight: bold;
    }
  }
}
.courseGroupList{
  width:94%;
  margin:0 3%;
  padding-bottom: 0.8rem;
}
.my-swipe{
  width: 100%;
  height: 100%;
  background:#fff;
}
.my-swipe .van-swipe-item img{
  width:94%;
  margin:0 3%;
  border-radius:8px;
}
</style>
